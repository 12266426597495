<template>
  <div ref="tooltip" class="tooltip">
    <div
      v-show="tooltipData.web"
      class="tooltip-entry"
    >
      <strong>Website:</strong>
      <a :href="tooltipData.web">{{ tooltipData.web }}</a>
    </div>
    <div
      v-show="tooltipData.twitter"
      class="tooltip-entry"
    >
      <strong>Twitter:</strong>
      <a>{{ tooltipData.twitter }}</a>
    </div>
    <div
      v-show="tooltipData.email"
      class="tooltip-entry"
    >
      <strong>Email:</strong>
      <a :href="'mailto:'+tooltipData.email">{{tooltipData.email}}</a>
    </div>
    <div
      v-show="tooltipData.riot"
      class="tooltip-entry"
    >
      <strong>Riot:</strong>
      <a>{{tooltipData.riot}}</a>
    </div>
    <div
      class="tooltip-entry"
    >
      <strong>Nominators count:</strong>
      <a>{{ tooltipData.nomCount }}</a>
    </div>
    <img
      @click="hideTooltip"
      class="close-tooltip"
      src="@/assets/close-small-icon.svg"
      alt=""
    >
  </div>
  <table>
    <thead>
      <tr>
        <th><img src="@/assets/checkbox-disabled.svg" alt=""></th>
        <!-- <th class="rank">Rank</th>   -->
        <th class="rank">№</th>
        <th>Name</th>
        <th>Amount staked</th>
        <th>Pending reward</th>
        <th>Nominate part</th>
        <th>Era points</th>
        <th style="width: fit-content; text-align: center;">Commission</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(validator, num) in validators[sel]"
        :key="num"
        :class="validator.selected ? 'selected' : ''"
      >
        <td><CheckBox
          v-model="validator.selected"
          @change="checkSelect(validator.address)"/>
        </td>
        <td class="text-center">{{ num + 1 }}</td>
        <!-- TODO: center -->
        <td><img
              @click="copyAddress(validator.address)"
              class="user-pic"
              src="@/assets/user-icon.svg" alt="User"
            >
              {{ validator.name || validator.address }}
            <img
              @click="showTooltip($event, validator.address)"
              class="info-img"
              src="@/assets/info-gray-icon.svg"
              alt="i"
            />
        </td>
        <td>{{ validator.staked || '' }} <span class="grayed" v-show="validator.staked">{{ tokenSymbol }}</span></td>
        <td>{{ validator.reward || '' }} <span v-show="validator.reward" class="grayed">{{ tokenSymbol }}</span></td>
        <td>{{ validator.nominated || '' }} <span v-show="validator.nominated" class="grayed">{{ tokenSymbol }}</span></td>
        <td>{{ validator.points || '' }}</td>
        <td class="text-center">{{ validator.commission }}</td>
      </tr>
    </tbody>
  </table>
  <div class="preloader" v-show="!preloader">
    <!-- TODO: make it beautiful with animation <3 -->
    <p style="text-align: center;">LOADING {{validators[sel].length}}/{{selectedLen}}</p>
    <svg width="184" height="144" viewBox="0 0 184 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7">
          <path d="M36.8242 76.178L113.001 0L113.003 0.00106812L253.533 140.532L227.268 139.661L113.001 25.3937L49.5205 88.8743L36.8242 76.178Z" fill="white"/>
          <path d="M113.002 101.572L183.704 172.273L168.001 181.966L113.002 126.964L100.308 139.661L87.6106 126.963L113.002 101.572Z" fill="white"/>
          <path d="M113.002 76.1801L19.3674 169.815L0.5 162.33L113.003 50.7875L163.787 101.571L151.09 114.268L113.002 76.1801Z" fill="white"/>
      </g>
    </svg>
  </div>
</template>

<script setup>

import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect
} from 'vue'

import { useStakingStore } from '@/stores/stakingStore'
import { useChainsStore } from '@/stores/chainsStore'

import CheckBox from '@/components/CheckBox.vue'

const chainsStore = useChainsStore()
const stakingStore = useStakingStore()

const tokenSymbol = chainsStore.tokenSymbol

const sel = ref('active')
const tooltip = ref()
const tooltipData = ref({})

const selectedLen = ref(undefined)
const validators = reactive({
  active: [],
  inactive: []
})

onMounted(() => {
  stakingStore.flushNomination()
})

const preloader = computed(
  () => {
    return validators[sel.value].length === selectedLen.value
  }
)

watch(
  () => stakingStore.nomination.validators?.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > newVal) {
      validators[sel.value]?.forEach(v => {
        v.selected = false
      })
    }
  }
)

watchEffect(() => {
  sel.value = stakingStore.validatorsSelectedType
  console.debug(sel.value)
  if (chainsStore.api) {
    prepareAllValidators()
  }
})

async function prepareAllValidators () {
  const opts = await fetchNodeStakingInfo()
  sel.value = stakingStore.validatorsSelectedType
  if (sel.value === 'active') {
    selectedLen.value = stakingStore.validatorsActive.length
    prepareActiveValidators(opts)
  } else {
    selectedLen.value = stakingStore.validatorsInactive.length
    console.debug('loading inactive')
    prepareInctiveValidators(opts)
  }
}

// dirty hack to push async
async function pushAsync (where, what) {
  where?.push(await what)
}

/*
  TODO:
  make prepareActiveValidators and prepareInctiveValidators
  DRY. Cuz many repetitions
*/

async function prepareActiveValidators (options) {
  validators.active = []

  console.debug('call to active')
  for (const v of stakingStore.validatorsActive) {
    const filledValidatorPromise = getValidatorInfo(v, options.currentEra, options.rewardsJSON)
    pushAsync(validators.active, filledValidatorPromise)
  }
}

async function prepareInctiveValidators (options) {
  validators.inactive = []

  console.debug('call to inactive')
  console.debug('inactive validators', stakingStore.validatorsInactive)
  for (const v of stakingStore.validatorsInactive) {
    const filledValidatorPromise = getValidatorInfo(v, options.currentEra, options.rewardsJSON)
    pushAsync(validators.inactive, filledValidatorPromise)
  }
}

async function fetchNodeStakingInfo () {
  const currentEra = await chainsStore.api?.query.staking.activeEra()
  const eraRewards = await chainsStore.api?.query.staking
    .erasRewardPoints(currentEra.value.index)
  const rewardsJSON = eraRewards?.individual.toJSON()

  return {
    currentEra: currentEra?.value.index,
    rewardsJSON
  }
}

async function getValidatorInfo (validator, era, eraRewards) {
  const vStr = `${validator}`
  const stakeInfo = await chainsStore.api.query.staking.erasStakers(era, vStr)
  // validator own stake
  const staked = parseFloat(
    (stakeInfo.own / Math.pow(10, chainsStore.decimals))
      .toFixed(6)
  )
  // stake of the nominators for the given validator
  const nominated = parseFloat(
    ((stakeInfo.total - stakeInfo.own) / Math.pow(10, chainsStore.decimals))
      .toFixed(6)
  )
  const nomCount = stakeInfo.others.length
  const vInf = await chainsStore.api.query.staking.validators(vStr)
  const commission = vInf.commission.toHuman()
  const points = eraRewards[vStr]
  const identity = await getIdentity(vStr)
  const [
    name,
    email,
    web,
    twitter,
    riot
  ] = [
    getNameFromIdentity(identity),
    identity.email,
    identity.web,
    identity.twitter,
    identity.riot
  ]

  return {
    address: vStr,
    rank: 0,
    reward: 0,
    selected: false,
    name,
    staked,
    nominated,
    nomCount,
    points,
    commission,
    email,
    web,
    twitter,
    riot
  }
}

function getNameFromIdentity (identity) {
  const display = identity.display
  const pDisplay = identity.displayParent
  const fullName = (pDisplay ?? '') + (display || '')
  return fullName
}

async function getIdentity (address) {
  const identity = await chainsStore.api.derive.accounts.identity(address)
  return identity
}

function showTooltip (ev, addr) {
  tooltipData.value = validators[sel.value].find(v => v.address === addr)
  tooltip.value.style.top = (ev.pageY + 10) + 'px'
  tooltip.value.style.left = (ev.pageX + 10) + 'px'
  tooltip.value.style.visibility = 'visible'
}

function hideTooltip () {
  tooltip.value.style.visibility = 'hidden'
}

function checkSelect (addr) {
  if (stakingStore.nomination.validators.findIndex(a => a === addr) !== -1) {
    stakingStore.nomination.validators = stakingStore.nomination.validators.filter(a => a !== addr)
  } else {
    stakingStore.nomination.validators.push(addr)
  }
}

function copyAddress (address) {
  navigator.clipboard.writeText(address)
}

</script>

<style scoped>
table {
  width: 100%;
  border-spacing: 0;
}

td {
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

td.commission {
  text-align: center;
}

tbody tr.selected td:nth-child(n+2) {
  background-color: rgba(126, 126, 126, 0.4);
}

tbody tr.selected td:nth-child(2) {
  border-radius: 10px 0 0 10px;
}

tbody tr.selected td:last-child {
  border-radius: 0 10px 10px 0;
}

th {
  text-align: start;
}

thead tr th:first-child {
  width: 2.5rem;
}

.grayed {
  color: #9E9E9E;
}

.rank {
  width: 3rem;
}

.text-center {
  text-align: center;
}

.user-pic {
  width: 40px;
  cursor: copy;
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: .5rem;

  position: absolute;
  visibility: hidden;

  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  max-width: 40%;
  min-width: 10%;
  border-radius: 0 10px 10px;
  padding: 0.5rem;
  height: fit-content;
}

.tooltip-entry {
  display: flex;
  flex-direction: column;
}

.close-tooltip {
  position: absolute;
  top: -0.5rem;
  width: 24px;
  right: -0.9rem;
  cursor: pointer;
}

.preloader {
  position: fixed;
  top: 40%;
  left: 50%;
}

.info-img {
  cursor: pointer;
}

</style>
