<template>
  <div class="validators">
    <ValidatorsConfig />
    <ValidatorsTable />
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

import { useStakingStore } from '@/stores/stakingStore'

import ValidatorsConfig from './ValidatorsConfig.vue'
import ValidatorsTable from './ValidatorsTable.vue'

const store = useStakingStore()

onMounted(
  () => {
    if (!store.validatorsInactive.length) {
      store.fetchWaitingValidators()
    }
  }
)

</script>

<style scoped>
.validators {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

</style>
