const PLANK = 1
// micro
const uDOT = Math.pow(10, 4)
// milli
const mDOT = Math.pow(10, 7)
// self
const DOT = Math.pow(10, 10)
// million
const MDOT = Math.pow(10, 16)

/**
* translate currency value from one representation to another
* for example
  `translate(2, DOT, PLANK)` will return 20000000000
* and `translate(300, mDOT, DOT)` will return 0.3
**/
function translate (value, from, to) {
  // TODO: is it safe? Check
  return value * from / to
}

function calculate (value, as) {
  return value * as
}

export { calculate, translate, PLANK, uDOT, mDOT, DOT, MDOT }
