import { createRouter, createWebHistory } from 'vue-router'
import WalletView from '../views/WalletView.vue'
import CustomStake from '@/views/StakingView.vue'
import FaqView from '../views/FAQ.vue'
import NotFoundView from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: WalletView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
  {
    path: '/custom-stake',
    name: 'CustomStake',
    component: CustomStake
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
