<template>
  <div class="faq-component">
    <button @click="router.back()"></button>
    <h1 class="faq-text">FAQ</h1>
    About Yamata
    landing.yamata.com
    Polkadot
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()

</script>

<style scoped>
.faq-component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  position: absolute;
  width: 1040px;
  height: 712px;
  left: calc(50% - 1040px/2);
  top: 100px;
}

.faq-text {

  width: 102px;
  height: 66px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  line-height: 66px;

  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;

}

</style>
