<template>
    <div class="chains">
      <h1 style="color: #fff;">Chains:</h1>
      <div class="chains__cards">
        <div
          v-for="(chain, id) in chainsStore.chains"
          :key="id"
          :class="['chain-card', chain.isActive ? 'grab-chain': '']"
          @click="proceedStake(id)"
        >
          <!-- basic chain info -->
          <div class="chain-basic">
            <img style="border-radius: 90px;" width="40" :src="require(`@/assets/chains-icons/${chain.info}.svg`)" alt="">
            <span :class="chain.isActive ? 'active-chain' : ''">{{ chain.text }}</span>
          </div>
          <!-- additional chain info -->
          <div class="chain-additional">
            <chain-info :display="chain.isActive"></chain-info>
            <connect-button
              :chain="chain"
              @click="connect(id, chain)">
            </connect-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

import { connectToNode } from '@/polkadot'

import ChainInfo from '@/components/wallet/chains/ChainInfo.vue'
import ConnectButton from '@/components/wallet/chains/ConnectButton.vue'

import { useChainsStore } from '@/stores/chainsStore'
import { useStakingStore } from '@/stores/stakingStore'

const chainsStore = useChainsStore()
const stakingStore = useStakingStore()
const router = useRouter()

async function connect (num, chain) {
  if (chainsStore.api?.isConnected) {
    await Promise.all([
      chainsStore.api.disconnect(),
      window.api.disconnect() // TODO: debug only
    ])
    chainsStore.chains[chainsStore.selectedChain].isActive = false
    stakingStore.flushValidators()
  }

  const apiPromise = connectToNode(chain.ws)
  try {
    const api = await apiPromise.isReadyOrError
    window.api = api // TODO: debug only
    const chainProperties = api.registry.getChainProperties()
    const tokenSymbol = chainProperties.tokenSymbol.value[0]
    const decimals = chainProperties.tokenDecimals.value[0].toNumber()
    /*
    for some reason
    chainsStore.$patch({
      api: api
    })
    doesn't work. Maybe because async is used
    */
    chainsStore.api = api
    chainsStore.$patch({
      selectedChain: num,
      tokenSymbol: tokenSymbol || 'TOK',
      decimals
    })
    chain.isActive = true
  } catch (error) {
    // TODO: show error
    console.error(error)
    apiPromise.disconnect()
  }
}

function proceedStake (id) {
  if (stakingStore.disabledPopUp && id === chainsStore.selectedChain) {
    router.push('/custom-stake')
    return
  }
  stakingStore.showPopUp = id === chainsStore.selectedChain
}
</script>

<style scoped>
.chains {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;

  position: relative;
  width: 100%;
}

.chains__cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;

  width: 100%;
}

.chain-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 10px;
  isolation: isolate;

  width: 220px;
  height: 100px;

  background: #2C2C2C;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.chain-basic {
  display: flex;
  flex-direction: row;
  gap: 4px;

  align-items: center;
  font-size: 16px;
  color: #fff;

  position: relative;
  height: 50%;
  width: 100%;
}

.chain-additional {
  display: flex;
  position: relative;
  height: 50%;
  width: 100%;
}

.active-chain {
  color: #FF7A00
}

.grab-chain {
  cursor: pointer;
}

</style>
