<template>
  <div :class="popup">
    <div class="background"></div>
    <div class="stake-container">
      <img @click="stakingStore.closePopup"
        class="close"
        src="@/assets/close-icon.svg" alt="X">
      <HeaderComponent />
      <div class="user">
        <UserCard />
        <AmountInput />
      </div>
      <p class="heading">through the following validators:</p>
      <ValidatorsComponent />
      <p class="heading">and recieve ~ SOME {{ chainsStore.tokenSymbol }}</p>
      <ButtonsComponent />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import HeaderComponent from '@/components/staking/popup/HeaderComponent.vue'
import AmountInput from '@/components/staking/popup/AmountInput.vue'
import UserCard from '@/components/wallet/user-info/UserCard.vue'
import ValidatorsComponent from '@/components/staking/popup/ValidatorsList.vue'
import ButtonsComponent from '@/components/staking/popup/ButtonsComponent.vue'

import { useStakingStore } from '@/stores/stakingStore'
import { useChainsStore } from '@/stores/chainsStore'

const stakingStore = useStakingStore()
const chainsStore = useChainsStore()

const popup = computed(() => {
  return stakingStore.showPopUp ? 'popup' : 'invisible'
})

</script>

<style scoped>
.invisible {
  display: none;
}

.popup {
  display: flex;

  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;

  color: #FFF;

  animation-name: fade-in;
  animation-duration: 600ms;
  animation-fill-mode: forwards;

  z-index: 1;
}

.close {
  position: absolute;
  width: 20px;
  left: 96%;
  top: 2%;

  cursor: pointer;
}

.close:hover {
  transform: rotate(360deg);
  transition: transform 0.5s;
}

.background {
  background: rgba(31, 31, 31, 0.8);
  filter: blur(10px);

  position: absolute;
  width: 100%;
  height: 100%;
}

.stake-container {
  display: flex;
  flex-direction: column;

  position: relative;
  width: 80%;
  height: 83%;
  margin: 4rem auto;
  padding: 1.5em;
  gap: 1em;

  background-color: #1F1F1F;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}

.user {
  display: flex;
  flex-direction: row;
  gap: 2em;
  align-items: center;
}

.heading {
  font-size: 24px;
  font-weight: 400;
  min-width: 6rem;
  margin: 0;
}

@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 1;}
}

</style>
