import { defineStore } from 'pinia'

export const useMainStore = defineStore('mainStore', {
  state: () => ({
    extension: false,
    userAccounts: [],
    selectedAccount: {},
    stakePopUp: false
  })
})
