<template>
  <div>
    <strong>Error: </strong>
    <span><slot></slot></span>
  </div>
</template>

<script>
export default {
  name: 'ErrorElem'
}
</script>
