<template>
  <div class="user-card bg-orange-grad">
    <connect-button
      v-if="!connected && !err.occurred"
      class="user-card__button bg-orange-grad"
      @result="handleResult"
    >connect extension</connect-button>
    <user-profile
      class="fade-in"
      v-else-if="connected"></user-profile>
    <div class="error error-fade-color" v-else-if="err">
      <error-elem>{{err.text}}</error-elem>
      <connect-button
        class="reconnect"
        @result="handleResult"
      >try to reconnect</connect-button>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue'

import { useMainStore } from '@/stores/mainStore'

import ConnectButton from '@/components/account/ConnectButton.vue'
import UserProfile from '@/components/account/UserProfile.vue'
import ErrorElem from '@/components/Error.vue'

const store = useMainStore()

const err = reactive({
  occurred: false,
  text: ''
})

const connected = computed(() => {
  return store.extension
})

function handleResult (e) {
  err.occurred = e.error
  err.text = e.text
}
</script>

<style scoped>
.user-card {
  position: relative;
  width: 460px;
  height: 200px;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.user-card__button {
  position: relative;
  width: 98%;
  height: 96%;
  top: 2%;
  left: 1%;

  color: #fff;
  font-size: 18px;

  border-style: dashed;
  border-radius: 10px;
  border-color: #fff;
  border-width: 2px;
}

.bg-orange-grad {
  background: linear-gradient(180deg, #FF7A00 0%, #D10000 210.46%);
}

.error {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 28px;

  border-radius: 10px;
}

.reconnect {
  color: #fff;

  height: 15%;
  background-color: rgba(255, 0, 0, 0.1);
  border: none;
}

.reconnect:hover {
  border-bottom: #fff 1px solid;
}

.fade-in {
  animation-name: fade-this;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

.error-fade-color {
  animation-name: fade-this, from-red-to-inherit;
  animation-duration: 1.5s, 10.5s;
  animation-timing-function: linear, linear;
  animation-delay: 0s, 0.5s;
  animation-iteration-count: 1, infinite;
  animation-direction: normal, normal;
  animation-fill-mode: forwards, forwards;
}

@keyframes fade-this {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes from-red-to-inherit {
  0% {background-color: inherit;}
  50% {background-color: rgba(255, 0, 0, 0.8);}
  100% {background-color: inherit;}
}

</style>
