<template>
  <div class="container">
    <p class="heading">i want to nominate</p>
    <span class="amount-info">
      <input
        v-model="stakingStore.nomination.amount"
        class="amount-input"
        type="number"
        :placeholder="minStake"
      >
      {{ chainsStore.tokenSymbol }}
    </span>
  </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

import { useStakingStore } from '@/stores/stakingStore'
import { useChainsStore } from '@/stores/chainsStore'

import { DOT, PLANK, translate } from '@/helpers/currency'

const stakingStore = useStakingStore()
const minStake = ref('Minimum 1')

const chainsStore = useChainsStore()

watchEffect(async () => {
  if (chainsStore.api) {
    const min = await chainsStore.api.query.staking.minNominatorBond()

    minStake.value = `Minimum ${translate(min, PLANK, DOT)}`
  }
})

</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input::placeholder {
  font-size: 20px;
}

input::selection {
  background: #FF7A00;
}

input:focus {
  background-color: #2C2C2C;
}

.amount-input {
  background: inherit;
  color: #FFF;

  font-size: 48px;
  max-width: 10rem;
  border: none;
  padding: 0;
  text-align: center;
}

.amount-input:focus-visible {
  outline: none;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.amount-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 23px;
  gap: 2px;
}

.heading {
  font-size: 24px;
  font-weight: 400;
  min-width: 6rem;
  margin: 0;
}
</style>
