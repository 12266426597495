<template>
  <div
    v-show="display"
    class="chain-info"
  >
    <table>
      <tr>
        <th>Validators</th>
        <td>{{ validators }}</td>
      </tr>
      <tr>
        <th>Total stake</th>
        <td>{{ totalStake }} M{{tokenSymbol}}</td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'

import { useChainsStore } from '@/stores/chainsStore'
import { useStakingStore } from '@/stores/stakingStore'

const chainsStore = useChainsStore()
const stakingStore = useStakingStore()

const validators = ref(0)
const totalStake = ref(0)
const tokenSymbol = computed(() => {
  return chainsStore.tokenSymbol
})

// eslint-disable-next-line
defineProps({
  display: {
    type: Boolean
  }
})

watch(
  () => chainsStore.api,
  async (api, _) => {
    const val = await api.query.session.validators()

    stakingStore.validatorsActive = val
    validators.value = val.length
    totalStake.value = await calcTotalStake(api)
  }
)

onMounted(async () => {
  if (chainsStore.api) {
    await refreshChainInfo(chainsStore.api)
  }
})

async function calcTotalStake (api) {
  const era = await api.query.staking.activeEra()
  const totalToks = await api.query.staking.erasTotalStake(era.value.index)
  return (totalToks / Math.pow(10, chainsStore.decimals + 6)).toFixed(6)
}

async function refreshChainInfo (api) {
  const v = await api.query.session.validators()
  stakingStore.validators = v
  validators.value = v.length
  totalStake.value = await calcTotalStake(api)
}

</script>

<style scoped>
th {
  text-align: left;
}

.chain-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 12px;
  color: rgba(180, 180, 180, 0.8);
}
</style>
