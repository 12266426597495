<template>
    <div>
      <h3>Nothink to see here.</h3>
      Maybe it will help to start from the main page.

      Also, be free to contact us for any question 😊
    </div>
</template>

<style scoped>

</style>
