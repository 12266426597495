<template>
  <div class="profile">
    <div class="profile__account">
      <img width="60" src="@/assets/yamata.svg" alt="YAMATA">
      <div class="account">
        <b class="account-name">{{ account.meta.name || 'NAMELESS_ACCOUNT' }}</b>
        <a class="account-address">
          {{ account.address }}
          <button class="copy-icon" @click="copyAddress"></button>
        </a>
      </div>
    </div>
    <div class="profile__balance">
      <a class="balance-text">Balance:</a>
      <h2 class="balance">{{ balance }} {{ chainsStore.tokenSymbol }}</h2>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watchEffect } from 'vue'

import { encodeAddress } from '@polkadot/util-crypto'

import { useMainStore } from '@/stores/mainStore'
import { useChainsStore } from '@/stores/chainsStore'

const store = useMainStore()
const chainsStore = useChainsStore()

const balance = ref('TBA')

const account = computed(() => {
  return store.selectedAccount
})

watchEffect(() => {
  const api = chainsStore.api
  if (api) {
    api.query.system.account(account.value.address, watchBalance)
    const prefix = api.consts.system.ss58Prefix.toNumber()

    store.selectedAccount.address = encodeAddress(store.selectedAccount.address, prefix)
  }
})

function watchBalance ({ data: b }) {
  const d = Math.pow(10, chainsStore.decimals)
  balance.value = b.free ? (b.free / d).toPrecision(5) : balance.value
}

function copyAddress () {
  navigator.clipboard.writeText(account.value.address)
}

</script>

<style scoped>
.profile {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  color: #fff;

  background-image: url('~@/assets/yamata-lines.svg');
  background-repeat: no-repeat;
  background-position-x: 15em;
  background-position-y: 4em;
}

.profile__account {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  width: fit-content;
  height: 50%;
  position: relative;
  left: 10px;
}

.account {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: fit-content;
  position: relative;
}

.account-address {
  color: #000;
  opacity: 0.75;
  font-size: 10px;
  font-weight: 600;
}

.account-name {
  text-shadow: 1px 1px 1px black;
  font-size: 24px;
}

.profile__balance {
  position: relative;
  left: 20px;
  width: fit-content;
  height: 50%;
}

.balance {
  text-shadow: 1px 1px 1px black;
  font-size: 45px;
  margin: 0;
}

.balance-text {
  font-family: 'Roboto-Thin';
  font-weight: 600;
}

.copy-icon {
  position: relative;
  top: 4px;

  background-image: url('~@/assets/copy-icon.svg');
  background-color: inherit;
  background-repeat: no-repeat;

  border: none;
  width: 14px;
  height: 16px;
}
</style>
