<template>
  <div class="validators-config">
    <div class="config-left">
      <button
        @click="switchToActive"
        :class="['switch', 'radius-left', classes.active]"
      >Active validators
      </button>
      <button
        @click="switchToInactive"
        :class="['switch', 'radius-right', classes.inactive]"
      >Inactive validators
      </button>
      <div class="checkbox-area">
        <CheckBox />
        <label>show only my staking list</label>
      </div>
    </div>
    <div class="config-right button-container">
      <button-claim class="btn">Claim reward</button-claim>
      <button-nominate class="btn">Nominate</button-nominate>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive } from 'vue'

import ButtonClaim from '@/components/staking/ButtonClaim.vue'
import ButtonNominate from '@/components/staking/ButtonNominate.vue'
import CheckBox from '@/components/CheckBox.vue'

import { useStakingStore } from '@/stores/stakingStore'

const stakingStore = useStakingStore()

const classes = reactive({
  active: 'active',
  inactive: 'inactive'
})

onMounted(
  () => {
    const sel = stakingStore.validatorsSelectedType
    if (sel !== 'active') {
      switchClasses()
    }
  }
)

function switchClasses () {
  [classes.active, classes.inactive] = [classes.inactive, classes.active]
}

function switchToActive () {
  classes.active = 'active'
  classes.inactive = 'inactive'
  stakingStore.validatorsSelectedType = 'active'
}

function switchToInactive () {
  classes.inactive = 'active'
  classes.active = 'inactive'
  stakingStore.validatorsSelectedType = 'inactive'
}

</script>

<style scoped>
.validators-config {
  display: flex;
  gap: 1rem;
}

.config-left {
  display: flex;
  flex-direction: row;
}

.checkbox-area {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.config-right {
  position: absolute;
  right: 7rem;
}

.button-container {
  display: flex;
  gap: 0.5rem;
}

.btn {
  width: 160px;
  height: 40px;
}

.switch {
  height: 40px;
  padding: 10px 20px;
}

.switch.active {
  background: #FFF;
  color: #000;
}

.switch.inactive {
  background: #000;
  color: #FFF;
  border: 1px solid #FFF;
}

.switch.radius-left {
  border-radius: 10px 0 0 10px;
}

.switch.radius-right {
  border-radius: 0 10px 10px 0;
}

@media screen and (max-width: 800px) {
  .validators-config {
    flex-direction: column;
  }

  .config-right {
    position: relative;
    right: 0;
  }
}

</style>
