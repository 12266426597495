<template>
  <div style="font-size: 38px; margin-bottom: 1rem; display: flex; gap: 0.5rem;">
    Easy nominate to {{ selectedChain }}
    <div class="tooltip">
      <img src="@/assets/info-icon.svg" alt="i">
      <span class="tooltiptext">
        <span style="opacity: 1;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam ultrices scelerisque nibh volutpat velit. Nascetur dictum et cras neque enim. Faucibus cursus aliquet turpis et gravida. Quis et, molestie a vitae orci in eu.</span>
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useChainsStore } from '@/stores/chainsStore'

const chainsStore = useChainsStore()

const selectedChain = computed(() => {
  const sel = chainsStore.chains[chainsStore.selectedChain]
  return sel === undefined ? '' : sel.text
})

</script>

<style scoped>
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  font-size: 14px;
  min-width: 30rem;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -880%;
  opacity: 0.9;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #FFF transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
