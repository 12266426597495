<template>
<header class="app-header">
  <div class="logo-scnetworks">
    <div class="logo-scnetworks__logo">
      <img src="@/assets/yamata.svg" alt="YAMATA">
      <router-link to="/" class="yamata-text">yamata</router-link>
    </div>
    <div class="logo-scnetworks__scnetworks">
      <a class="logo-scnetworks__scnetworks-network" id="github-lnk" href="#"></a>
      <a class="logo-scnetworks__scnetworks-network" id="reddit-lnk" href="#"></a>
      <a class="logo-scnetworks__scnetworks-network" id="discord-lnk" href="#"></a>
      <a class="logo-scnetworks__scnetworks-network" id="twitter-lnk" href="#"></a>
    </div>
  </div>
  <div class="right-menu">
    <a class="right-menu__mail" href="#"></a>
    <router-link class="right-menu__faq" to="/faq">FAQ</router-link>
    <a class="right-menu__privacy" href="#">Privacy</a>
  </div>
  <!-- TODO: only show when user is logged in? -->
  <div class="user-icon">
    <!-- TODO: drop-down menu -->
  </div>
</header>
</template>

<script>
export default {
  name: 'HeaderElem'
}
</script>

<style scoped>
.app-header {
  position: relative;
  height: 60px;

  background: #2C2C2C;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
}

.logo-scnetworks {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;

  position: absolute;
  width: 300px;
  height: 40px;
  left: 40px;
  top: 10px;
}

.logo-scnetworks__logo {
  width: 120px;
  height: 40px;

  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
}

.logo-scnetworks__logo-front {
  position: absolute;
  top: 2px;
  left: 2px;
}

.logo-scnetworks__scnetworks {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 140px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.logo-scnetworks__scnetworks-network {
  width: 20px;
  height: 20px;

  flex: none;
  flex-grow: 0;
}

.right-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 20px;

  position: absolute;
  width: 121px;
  height: 16px;
  right: 100px;
  top: calc(50% - 16px/2);
}

.right-menu__mail {
  width: 20px;
  height: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  background-image: url("~@/assets/mail.svg");
}

.right-menu__faq {
  width: 22px;
  height: 14px;

  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;

  color: #9E9E9E;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.right-menu__privacy {
  width: 39px;
  height: 14px;

  font-size: 12px;
  line-height: 14px;

  display: flex;
  align-items: center;

  color: #9E9E9E;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.user-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 40px;
  background-image: url('~@/assets/user-icon.svg');
  top: calc(50% - 40px/2);
}

.yamata-text {
  color:white;
  position: absolute;
  top: 0.5em;
  left: 3em;
}

/* using tags because this elements are unique across the app */

#github-lnk {
  background-image: url("~@/assets/sc-networks/github.svg");
}

#reddit-lnk {
  background-image: url("~@/assets/sc-networks/reddit.svg");
}

#discord-lnk {
  background-image: url("~@/assets/sc-networks/discord.svg");
}

#twitter-lnk {
  background-image: url("~@/assets/sc-networks/twitter.svg");
}

</style>
