<template>
  <button
    @click="stakingStore.nominate"
    class="btn nominate"
  >
    Nominate
  </button>
</template>

<script setup>
import { useStakingStore } from '@/stores/stakingStore'

const stakingStore = useStakingStore()
</script>

<style scoped>
button {
  color: #FFF;
  background-color: #FF7A00;
  border-radius: 10px;
}
</style>
