<template>
  <div class="validators">
    <div
        v-for="(validator, n) in validators"
        :key="n"
        class="validator"
    >
      <img @click="remove(validator, n)" class="remove" src="@/assets/close-small-icon.svg" alt="x">
      <img
        @click="copyAddress(validator.address)"
        class="user-pic"
        src="@/assets/user-icon.svg" alt="User">
      <div class="info">
        <a
          class='text-wrapped'
          :title="validator.address"
        > {{ validator.name || validator.address }} </a>
        <a style="font-size: 0.5rem">{{ validator.commission }}
          <span class="gray-text">Commission</span>
        </a>
      </div>
      <div class="balance">
        <span>{{ validator.totalStake }}</span>
        <!-- TODO: it should be possible to show amount in different dimensions, e.g. DOT, KDOT, MDOT -->
        <span class="gray-text">M{{ chainsStore.tokenSymbol }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue'

import { useStakingStore } from '@/stores/stakingStore'
import { useChainsStore } from '@/stores/chainsStore'

const stakingStore = useStakingStore()
const chainsStore = useChainsStore()
const validators = reactive([])

onMounted(
  fillNominations()
)

watch(
  () => stakingStore.showPopUp,
  async (n, _) => {
    if (n) {
      await fillNominations()
    }
  }
)

async function fillNominations () {
  stakingStore.flushNomination()
  validators.splice(0)

  const activeValis = stakingStore.validatorsActive
  if (chainsStore.api) {
    const maxNominations = chainsStore.api.consts.staking.maxNominations.toNumber()
    const validatorsSlice = activeValis.slice(0, maxNominations)

    const currentEra = await chainsStore.api.query.staking.activeEra()
    for (const v of validatorsSlice) {
      const vStr = `${v}`
      const stakeInfo = await chainsStore.api.query.staking.erasStakers(currentEra.value.index, vStr)
      const totalStake = (stakeInfo.total / Math.pow(10, chainsStore.decimals + 6)).toFixed(6)
      const res = await chainsStore.api.query.staking.validators(vStr)
      const identity = await chainsStore.api.derive.accounts.identity(vStr)
      validators.push({
        name: getNameFromIdentity(identity),
        address: vStr,
        commission: `${res.commission.toHuman()}`,
        totalStake: totalStake
      })
      stakingStore.nomination.validators.push(vStr)
    }
  }
}

// TODO: DRY (ValidatorsTable too)
function getNameFromIdentity (identity) {
  const display = identity.display
  const pDisplay = identity.displayParent
  const fullName = (pDisplay ?? '') + (display || '')
  return fullName
}

function copyAddress (address) {
  navigator.clipboard.writeText(address)
}

function remove (validator, n) {
  validators.splice(n, 1) // remove from current list
  stakingStore.nomination.validators = stakingStore.nomination.validators.filter(v => v !== validator.address)
  console.debug(`Nomination validators: ${stakingStore.nomination.validators}`)
}

// async function accBalance () {
//   const {_, data: balance} = await chainsStore.api.query.system.account()
// }

</script>

<style scoped>
.validators {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0.2rem;
  gap: 10px;
  width: 100%;
  height: 35%;
  flex-wrap: wrap;
  overflow: auto;
}

.validator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;

  width: 220px;
  height: 60px;
  padding: 10px;

  background: #2C2C2C;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.039), 0px 7px 24px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.gray-text {
  color: #9E9E9E;
}

.remove {
  display: block;
  visibility: hidden;

  position: absolute;
  top: -0.2rem;
  right: -0.3rem;

  cursor: pointer;
}

.validator:hover .remove {
  visibility: visible;
}

.user-pic {
  width: 40px;
  cursor: copy;
}

.info {
  display: flex;
  flex-direction: column;
}

.balance {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 2px;
  position: absolute;
  right: 0.5rem;
  font-size: 0.7rem;
}

.text-wrapped {
  width: 5rem;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

</style>
