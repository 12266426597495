<template>
  <div
    v-show="claimed"
    class="claim-bar"
  >
    <strong class="total-reward">Total claimable rewards:</strong>
    <h2>{{ claimed }} DOT</h2>
    <button-claim
      class="claim-bar__button"
    >claim</button-claim>
    <button
      class="claim-bar__button button-dark"
    >claim and refund</button>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import ButtonClaim from '@/components/staking/ButtonClaim.vue'

const claimed = ref(1)

</script>

<style scoped>
.claim-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  color: #fff;

  position: relative;
  width: max-content;
}

.claim-bar__button {
  width: 160px;
  height: 40px;
  border-radius: 10px;
}

.button-dark {
  border: 1px solid #fff;
  color: #fff;
  background-color: #000;

  filter: drop-shadow(0px 0.5px 5px rgba(0, 0, 0, 0.039)) drop-shadow(0px 3.75px 11px rgba(0, 0, 0, 0.19));
}

.total-reward {
  font-weight: 400;
}

</style>
