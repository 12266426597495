<template>
  <div class="information-bar">
    <div class="information-bar__container">
      <img class="information-bar__image" src="@/assets/yamata.svg" alt="YAMATA">
      <div class="information-bar__container-text">
        <strong id="hey" class="information-bar__text">Hey</strong>
        <strong id="welcome" class="information-bar__text">Welcome to Yamata! 😉</strong>
        <strong class="information-bar__text">Please, connect your wallet to start.</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationBar'
}
</script>

<style scoped>
.information-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

  color: white;
  position: absolute;
  width: 40%;
  height: 100%;
  right: 40px;

  background-image: linear-gradient(to bottom right, #2C2C2C, #382007, rgb(204, 102, 0, 0.5));
  border-radius: 20px;
}

.information-bar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  position: relative;
  width: max-content;
  height: fit-content;
  left: 40px;
  top: 40px;
}

.information-bar__image {
  width: 120px;
  height: 120px;

  flex: none;
  order: 0;
  flex-grow: 0;
  align-content: center;
}

.information-bar__container-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  width: 382px;
  height: 118px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.image-front {
  position: absolute;
  top: 7%;
  left: 8px;
}

/*
  TODO: create single class with the same properties
*/

#hey {
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
}

#welcome {
  width: max-content;
  height: 45px;

  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  /* identical to box height */
}

#please {
  width: 373px;
  height: 28px;

  font-weight: 300;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;
  flex: none;
  flex-grow: 0;
}

</style>
