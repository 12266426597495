import { defineStore } from 'pinia'

import { useChainsStore } from './chainsStore'

export const useStakingStore = defineStore('stakingStore', {
  state: () => ({
    showPopUp: false,
    disabledPopUp: false, // TODO: save to localstore
    nomination: {
      amount: undefined,
      validators: undefined
    },
    validatorsActive: [],
    validatorsInactive: [],
    validatorsSelectedType: 'active',
    validatorsSelectedList: []
  }),
  actions: {
    closePopup () {
      this.flushNomination()
      this.showPopUp = false
    },
    flushValidators () {
      this.validatorsActive = []
      this.validatorsInactive = []
    },
    /**
    * flush store nomination state:
    * amount and validators
    */
    flushNomination () {
      this.$patch({
        nomination: {
          amount: undefined,
          validators: []
        }
      })
    },
    async fetchWaitingValidators () {
      const chainsStore = useChainsStore()
      const allValidators = await chainsStore.api?.query.staking.validators.keys()
      const allAddresses = allValidators?.map(v => v.toHuman()[0])
      const activeAddresses = this.validatorsActive
      const inactive = allAddresses?.filter(
        v => v !== `${activeAddresses[activeAddresses.indexOf(v)]}`
      )
      this.validatorsInactive = inactive ?? []
    },
    nominate () {
      // TODO: realy nominate
      const x = {
        nominate: this.nomination.amount,
        Validators: this.nomination.validators
      }
      window.alert(
        `
        DEBUG
        ${JSON.stringify(x)}
        `
      )
      console.debug(x)
      this.flushNomination()
    }
  }
})
