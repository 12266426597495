<template>
  <button
    @click="connectExtension"
  ><slot></slot></button>
</template>

<script setup>
import { web3Enable, web3Accounts } from '@polkadot/extension-dapp'
import { useMainStore } from '@/stores/mainStore'

// eslint-disable-next-line
const emit = defineEmits(['result'])

const store = useMainStore()

async function connectExtension () {
  const result = {
    error: true,
    text: 'extension not found'
  }

  try {
    const extensions = await web3Enable('Yamata Wallet')
    if (extensions.length === 0) {
      emit('result', result)
      return
    }
    result.error = false

    const accs = await web3Accounts()
    store.userAccounts = accs
    // TODO: select account!
    // WARN
    store.$patch({
      selectedAccount: accs[0],
      extension: true
    })
    emit('result', result)
  } catch (error) {
    result.text = error
    emit('result', error)
  }
}
</script>
