import { defineStore } from 'pinia'

export const useChainsStore = defineStore('chainStore', {
  state: () => ({
    api: null,
    chains: [
      {
        info: 'debug',
        text: 'Test Node',
        isActive: false,
        ws: 'ws://localhost:9999' // TODO: make configurable in user settings
      },
      {
        info: 'polkadot',
        text: 'Polkadot',
        isActive: false,
        ws: 'wss://rpc.polkadot.io'
      },
      {
        info: 'kusama',
        text: 'Kusama',
        isActive: false,
        ws: 'wss://kusama-rpc.polkadot.io'
      }
    ],
    selectedChain: null,
    tokenSymbol: undefined,
    decimals: undefined
  })
})
