<template>
  <div class="btn-container">
    <button class="btn customize" @click="customize">Customize</button>
    <div class="show-container">
      <CheckBox v-model="stakingStore.disabledPopUp"/>
      <label class="text-sm">don`t show “easy nominate” window again</label>
    </div>
    <button
      @click="stakingStore.closePopup"
      class="btn cancel"
    >Cancel</button>
    <button-nominate></button-nominate>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStakingStore } from '@/stores/stakingStore'

import CheckBox from '@/components/CheckBox.vue'
import ButtonNominate from '@/components/staking/ButtonNominate.vue'

const stakingStore = useStakingStore()
const router = useRouter()

function customize () {
  stakingStore.showPopUp = false
  router.push('/custom-stake')
}
</script>

<style scoped>

.btn-container {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  position: absolute;
  width: 95%;
  bottom: 2rem;
}

.btn {
  width: 220px;
  height: 60px;
  box-shadow: 0px 0.5px 5px rgba(0, 0, 0, 0.039), 0px 3.75px 11px rgba(0, 0, 0, 0.19);

  border-radius: 10px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
}

.customize {
  background-color: #000;
  color: #FFF;
  border: 2px solid #FFF;
}

.cancel {
  background: #FFF;
}

.show-container {
  display: flex;
  align-items: center;
  align-content: center;
}

.show-container label:last-of-type {
  font-size: 12px;
  width: 9rem;
}

</style>
