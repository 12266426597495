<template>
  <div :class="['info-popup', status]">
    <div class="info-popup__content">
      <img v-if="status === 'accepted'" src="@/assets/accepted-icon.svg" alt="">
      <img v-else-if="status === 'rejected'" src="@/assets/rejected-icon.svg" alt="!">
      <p>request {{ status }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

// eslint-disable-next-line
const props = defineProps(['status'])

const status = computed(() => {
  return props.status
})
</script>

<style scoped>
.info-popup {
  display: flex;
  align-items: center;
  width: 300px;
  height: 100px;

  color: #000;
  border-radius: 10px;

  z-index: 1;
}

.rejected {
  background-color: #FF9696;
}

.accepted {
  background-color: #A3FA84;
}

.info-popup__content {
  display: flex;
  margin: 0 auto;
  font-size: 1.5em;
  gap: 10px;
}

p {
  width: 100%;
  text-align: center;
}

</style>
